import React from 'react';
import { Logo } from './features/logo/Logo';
import { useTheme } from '@material-ui/core/styles';
import { withTheme } from './themes/Theme';
import { DarkThemeToggle } from './features/darkTheme/DarkThemeToggle'
import './App.css';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { maxWidth } from '@mui/system';

function App() {
  const theme = useTheme();
  return (
    <Grid
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        margin: 0,
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: theme.palette.background.default,
        color: 'text.primary',
        borderRadius: 0,
        p: 3,
      }}
    >
      <Logo />
      <DarkThemeToggle />
      <Typography variant='h4' sx={{
        color: theme.palette.text.primary,
        maxWidth: '50%' }}>
        This site is under construction.
        Please click the logo on the top left to go to our main site
      </Typography>
    </Grid>
  );
}

export default withTheme(App);



